


export const getBackground = (status) => {
    return (
        status === "prelim_check" ? "#B3E5FC" : 
        status === "prelim_resp_exec_approved" ? "#c2ffe0" :
        status === "prelim_resp_exec_pending" ? "#ffc9c9" :
        status === "review" ? "#fef9c3ff" : 
        status === "approved" ? "#dcfce7ff" :
        status === "denied" ? "#ffecefff":
        "#e0e0e0")
}


export const getColor = (status) => {
    return (status === "review" ? "#905b20" : 
        status === "approved" ? "#1d6738" :
        status === "denied" ? "#f43f5f":
        "#e0e0e0")
}

