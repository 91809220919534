import * as React from 'react';
import { useState, useCallback, useEffect, forwardRef, useRef, useId } from 'react';
import { styled } from '@mui/joy/styles';
import { Box, Input, Typography } from '@mui/joy';

const StyledInput = styled('input')({
    border: 'none', // remove the native input border
    minWidth: 0, // remove the native input width
    outline: 0, // remove the native input outline
    padding: 0, // remove the native input padding
    flex: 1,
    color: 'inherit',
    backgroundColor: 'transparent',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    textOverflow: 'ellipsis',
    '&::placeholder': {
      opacity: 0,
      transition: '0.1s ease-out',
    },
    '&:-webkit-autofill': {
      alignSelf: 'stretch', // to fill the height of the root slot
    },
    '&:-webkit-autofill:not(* + &)': {
      marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
      paddingInlineStart: 'var(--Input-paddingInline)',
      borderTopLeftRadius:
        'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
      borderBottomLeftRadius:
        'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    },
    '--Input-focusedHighlight': 'none',
  });


const InnerInput = forwardRef(
    function InnerInput(props, ref) {
        const { onTruncationChange, ...rest } = props;
        const id = useId();
        const inputRef = useRef(null);

        useEffect(() => {
            const checkTruncation = () => {
                if (inputRef.current) {
                    const isTruncated = inputRef.current.scrollWidth > inputRef.current.clientWidth;
                    onTruncationChange(isTruncated);
                }
            };

            checkTruncation();
            
            // Check on window resize
            window.addEventListener('resize', checkTruncation);
            return () => window.removeEventListener('resize', checkTruncation);
        }, [onTruncationChange, props.value]);

        return (
            <React.Fragment>
                <StyledInput {...rest} ref={(node) => {
                    // Handle both refs
                    if (typeof ref === 'function') {
                        ref(node);
                    } else if (ref) {
                        ref.current = node;
                    }
                    inputRef.current = node;
                }} id={id} />
            </React.Fragment>
        );
    }
);


function StringInputAutoForm(props) {
    const { label, labelMinWidth, icon, type, ...rest } = props;
	const [ isTruncated, setIsTruncated ] = useState(false);

	const handleTruncationChange = useCallback((truncated) => {
		setIsTruncated(truncated);
	}, []);

    return (
        <Box sx={{ display: 'flex'}}>
            <Typography sx={{ minWidth: labelMinWidth, alignSelf: "flex-end", paddingRight: 1}}>
                {label}: 
            </Typography>
            <Input
                endDecorator={icon}
                slots={{ input: InnerInput }}
                slotProps={{ input: { placeholder: '', type: type, label, onTruncationChange: handleTruncationChange } }}
                autoComplete="off"
                sx={{
                    width: isTruncated && props.disabled ? '100%' : '50%',
                    '--Input-radius': '0px',
                    border: 'none',
                    borderColor: 'transparent',
                    borderBottom: '1px grey solid',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                    '&:hover': {
                    borderColor: 'neutral.outlinedHoverBorder',
                    },
                    '&::before': {
                        bordeBottom: 'none',
                        border: '2px solid var(--Input-focusedHighlight)',
                        transform: 'scaleX(0)',
                        left: 0,
                        right: 0,
                        bottom: '-2px',
                        top: 'unset',
                        transition: 'transform .15s cubic-bezier(0.1,0.9,0.2,1)',
                        borderRadius: 0,
                    },
                    '&:focus-within::before': {
                    transform: 'scaleX(1)',
                    },
                }}
                {...rest}
            />
        </Box>
    );
}

export default StringInputAutoForm;


