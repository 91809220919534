import { Box } from "@mui/joy";
import React, { useEffect, useRef, useState } from "react";
import StringInputAutoForm from "./form-filling-auto/StringInput";
import SelectInputAutoForm from "./form-filling-auto/SelectInput";
import DateInput from "./form-filling-auto/DateInput";
import TimeInput from "./form-filling-auto/TimeInput";
import RadioButtonAutoForm from "./form-filling-auto/RadioButton";
import TextAreaInput from "./form-filling-auto/TextAreaInput";
import VitalsTableInput from "./form-filling-auto/VitalsTableInput";
import IntegerInputAutoForm from "./form-filling-auto/IntegerInput";
import HeadersTableInput from "./form-filling-auto/HeadersTableInput";
import { LocationOfInjuries } from "./form-filling-auto/LocationOfInjuries";
import { convertJSONKeyToTitle } from "../helpers/KeyConverters";

import { convertToUTCDate } from "../helpers/TimeConversionHelper";
import { newTotalInputs } from "../helpers/DecideBetweenUserEditOrGenerated";
import CheckBoxAutoForm from "./form-filling-auto/CheckBoxButton";


function AutoBuildFormSection(props) {
    const formSectionInputs = useRef({});
    const [ seedState, setSeedState ] = useState(null);
    const [ initialValues, setInitialValues ] = useState(null);
    const [ maxLabelWidth, setMaxLabelWidth ] = useState(0);

    const setupComponents = (dictionary) => {
        // Determine between patients vs ([user_edit][value] vs [generated_result][value])
        let intoInput;
        try {
            const newFormSectionInputs = {};
            if (!props.initialInput || (typeof props.initialInput !== 'object') || props.initialInput === undefined) {
                intoInput = {};
            } else {
                // Patient and Form vs. Actual form sections
                if (!("generated_result" in props.initialInput) && !("user_edit" in props.initialInput)) {
                    intoInput = props.initialInput;
                } else if (("generated_result" in props.initialInput) && !("user_edit" in props.initialInput)) {
                    // For recalled visits, check if "value" exists as well
                    intoInput = props.initialInput['generated_result']['value'];
                } else if (!("generated_result" in props.initialInput) && ("user_edit" in props.initialInput)) {
                    intoInput = props.initialInput['user_edit']['value'];
                } else {
                    if (("value" in props.initialInput["user_edit"]) && ("value" in props.initialInput["generated_result"])) {
                        intoInput = newTotalInputs(props.initialInput["user_edit"]["value"], props.initialInput["generated_result"]["value"], dictionary)
                    } else if (("value" in props.initialInput["user_edit"]) && !("value" in props.initialInput["generated_result"])) {
                        intoInput = props.initialInput['user_edit']['value'];
                    } else if (!("value" in props.initialInput["user_edit"]) && ("value" in props.initialInput["generated_result"])) {
                        intoInput = props.initialInput['generated_result']['value'];
                    }
                }
            }
            for (const key in dictionary) {
                if (key === "field_order") {
                    continue;
                }
                if (!(key in intoInput)) {
                    if (dictionary[key]['type'] === "list") {
                        newFormSectionInputs[key] = [];
                    } else if (dictionary[key]["type"] === "boolean") {
                        newFormSectionInputs[key] = false;
                    } else if (dictionary[key]["type"] === "dictionary") {
                        newFormSectionInputs[key] = {};
                    } else {
                        newFormSectionInputs[key] = undefined;
                    }
                } else {
                    newFormSectionInputs[key] = intoInput[key]
                }
            }
            formSectionInputs.current = newFormSectionInputs;
            setInitialValues(newFormSectionInputs);
        } catch(error) {
            console.log(error);
            const newFormSectionInputs = {};
            for (const key in dictionary) {
                if (key === "field_order") {
                    continue;
                }
                if (dictionary[key]['type'] === "list") {
                    newFormSectionInputs[key] = [];
                } else if (dictionary[key]["type"] === "boolean") {
                    newFormSectionInputs[key] = false;
                } else if (dictionary[key]["type"] === "dictionary") {
                    newFormSectionInputs[key] = {};
                } else {
                    newFormSectionInputs[key] = undefined;
                }
            }

            formSectionInputs.current = newFormSectionInputs;
            setInitialValues(newFormSectionInputs);
        }
    };

    const reset = () => {
        setSeedState(Math.random());
    }
    
    const calculateMaxLabelWidth = (formFields) => {
        const labelWidths = Object.keys(formFields).map(key => 
            convertJSONKeyToTitle(key).length
        );
        
        // Sort the widths in descending order
        labelWidths.sort((a, b) => b - a);
        
        // Normally, 20 chars isn't exceeded for the labels
        const maxAllowedWidth = 20;
        
        // If the highest width exceeds the maximum allowed, use the second highest
        if (labelWidths[0] > maxAllowedWidth && labelWidths.length > 1) {
            return labelWidths[1];
        }
        
        return labelWidths[0];
    };

    useEffect(() => {
        props.retrieveInput(initialValues)
    }, [initialValues])

    useEffect(() => {
        // Use props.formFields, iterate through each key value pair to determine the amount of values needed 
        reset();
        setupComponents(props.formFields);
        setMaxLabelWidth(calculateMaxLabelWidth(props.formFields));
    }, [props.formFields]);

    return <form>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2.5, pb: "2rem"}} key={seedState}>
            {/* Enumerate the formAutoState, depending on the parsed type, render the proper form fields => Use map, then if statement checking the form type*/}
                {initialValues && (props.formFields["field_order"]).map( key => {
                    if (props.formFields[key]["type"] === 'string') {
                        if (props.formFields[key]["display"] === "dropdown") {
                            return (
                                <Box sx={{ flex: '1 1 45%', pl: '1rem' }}> 
                                    <SelectInputAutoForm 
                                        key={key}
                                        label={convertJSONKeyToTitle(key)}
                                        multiple={false}
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        options={props.formFields[key].values}
                                        defaultValue={formSectionInputs.current[key]}
                                        setEmpty={() => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: "",
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}
                                        onChange={(event) => {
                                            if (!event) {
                                                return;
                                            }
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: event.target.textContent,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}  
                                    />
                                </Box>
                            );
                        } 
                        else if (props.formFields[key]["display"] === "textarea") {
                            if (props.formFields[key]["size"] === "medium") {
                                return (
                                    <Box sx={{ flex: '45%', px: '1%'}}>
                                        <TextAreaInput
                                            key={key}
                                            disabled={props.disabled}
                                            label={convertJSONKeyToTitle(key)}
                                            labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                            value={initialValues[key]}
                                            onChange={(event) => {
                                                setInitialValues((currentValues) => ({
                                                    ...currentValues,
                                                    [key]: event.target.value,
                                                }));
                                                !props.dirty && props.setDirty(true);
                                            }}
                                        />
                                    </Box>
                                );
                            } else if (props.formFields[key]["size"] === "small") {
                                return (
                                    <Box sx={{ flex: '30%', px: '1%', height: "9%"}}>
                                        <TextAreaInput
                                            key={key}
                                            height={"100%"}
                                            disabled={props.disabled}
                                            label={convertJSONKeyToTitle(key)}
                                            labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                            value={initialValues[key]}
                                            onChange={(event) => {
                                                setInitialValues((currentValues) => ({
                                                    ...currentValues,
                                                    [key]: event.target.value,
                                                }));
                                                !props.dirty && props.setDirty(true);
                                            }}
                                        />
                                    </Box>
                                );
                            } else {
                                return (
                                    <Box sx={{ flex: '100%', pl: '1rem' }}>
                                        <TextAreaInput
                                            key={key}
                                            label={convertJSONKeyToTitle(key)}
                                            disabled={props.disabled}
                                            labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                            value={initialValues[key]}
                                            onChange={(event) => {
                                                setInitialValues((currentValues) => ({
                                                    ...currentValues,
                                                    [key]: event.target.value,
                                                }));
                                                !props.dirty && props.setDirty(true);
                                            }}
                                        />
                                    </Box>
                                );
                            }
                        }
                        else {
                            return (
                                <Box sx={{ 
                                    flex: (() => {
                                        // If in past visits
                                        if (props.disabled && typeof initialValues[key] === 'string') {
                                            return initialValues[key].length > (maxLabelWidth * 1.44) ? '1 1 100%' : '1 1 45%';
                                        }
                                        else {
                                            return '1 1 45%'
                                        }
                                    })(),
                                    pl: '1rem'
                                }}>
                                    <StringInputAutoForm 
                                        key={key}
                                        label={convertJSONKeyToTitle(key)} 
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        defaultValue={formSectionInputs.current[key]}
                                        value={initialValues[key]}
                                        onChange={(event) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: event.target.value,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}>
                                    </StringInputAutoForm>
                                </Box>
                            )
                        }
                    }
                    else if (props.formFields[key]["type"] === 'integer') {
                        return (
                            <Box sx={{ flex: '1 1 45%', pl: '1rem' }}>
                                <IntegerInputAutoForm
                                    key={key}
                                    label={convertJSONKeyToTitle(key)}
                                    disabled={props.disabled}
                                    labelMinWidth={`${maxLabelWidth * 8}px`}
                                    defaultValue={formSectionInputs.current[key]}
                                    onChange={(value) => {
                                        setInitialValues((currentValues) => ({
                                            ...currentValues,
                                            [key]: value,
                                        }));
                                        !props.dirty && props.setDirty(true);
                                    }}
                                />
                            </Box>
                        )
                    }
                    else if (props.formFields[key]["type"] === 'datetime') {
                        if (props.formFields[key]["display"] === "datetime_date") {
                            return (
                                <Box sx={{ flex: '1 1 45%', pl: '1rem' }}>
                                    <DateInput
                                        key={key}
                                        label={convertJSONKeyToTitle(key)} 
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        defaultValue={formSectionInputs.current[key]}
                                        value={initialValues[key]}
                                        onChange={(value) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: convertToUTCDate(value),
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}
                                    />
                                </Box>
                            );
                        } else if (props.formFields[key]["display"] === "datetime_hour") {
                            return (
                                <Box sx={{ flex: '1 1 45%', pl: '1rem' }}>
                                    <TimeInput
                                        key={key}
                                        label={key === "efrt_activation_time" ? "EMS Activation Time" : convertJSONKeyToTitle(key)} 
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        defaultValue={formSectionInputs.current[key]}
                                        onChange={(value) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: value,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}
                                    />
                                </Box>
                            );
                        }
                    }
                    else if (props.formFields[key]["type"] === 'toggle') {
                        return (
                            <Box sx={{ flex: '1 1 45%', pl: '1rem', display: "flex", alignSelf: "center" }}> 
                                <RadioButtonAutoForm
                                    key={key}
                                    label={convertJSONKeyToTitle(key)}
                                    disabled={props.disabled}
                                    labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                    values={props.formFields[key].values}
                                    initialValue={formSectionInputs.current[key]}
                                    onChange={(event) => {
                                        setInitialValues((currentValues) => ({
                                            ...currentValues,
                                            [key]: event.target.value,
                                        }));
                                        !props.dirty && props.setDirty(true);
                                    }}
                                />
                            </Box>
                        );
                    }
                    else if (props.formFields[key]["type"] === 'boolean') {
                        if (props.formFields[key]["display"] === 'checkbox') {
                            return (
                                <Box sx={{ flex: '1 1 45%', pl: '1rem' }}>
                                    <CheckBoxAutoForm
                                        key={key}
                                        label={convertJSONKeyToTitle(key)}
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        defaultValue={formSectionInputs.current[key]}
                                        onChange={(value) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: value,
                                            }))
                                            !props.dirty && props.setDirty(true);
                                        }}
                                    />
                                </Box>
                            )
                        }
                    }
                    else if (props.formFields[key]["type"] === 'list') {
                        if (props.formFields[key]["display"] === "multi_select_dropdown") {
                            if (!Array.isArray(formSectionInputs.current[key])) {
                                let fetchEmAll = [];
                                for (let option in props.formFields[key].values) {
                                    if (formSectionInputs.current[key].includes(props.formFields[key].values[option])) {
                                        fetchEmAll.push(props.formFields[key].values[option]);
                                    }
                                }
                                formSectionInputs.current[key] = fetchEmAll;
                                setInitialValues((currentValues) => ({
                                    ...currentValues,
                                    [key]: fetchEmAll,
                                }));
                            }
                            return (
                                <Box sx={{ flex: '1 1 45%', pl: '1rem' }}> 
                                    <SelectInputAutoForm 
                                        key={key}
                                        label={convertJSONKeyToTitle(key)}
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        options={props.formFields[key].values}
                                        defaultValue={formSectionInputs.current[key]}
                                        multiple={true}
                                        onChange={(event) => {
                                            if (!event) {
                                                return;
                                            }
                                            let currents = initialValues[key];
                                            if (currents.includes(event.target.textContent)) {
                                                currents = currents.filter(current => current !== event.target.textContent)
                                            } else {
                                                currents.push(event.target.textContent);
                                            }
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: currents,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}  
                                    />
                                </Box>
                            );
                        }
                        else if (props.formFields[key]["display"] === "image") {
                            return (
                            <Box sx={{ flex: '45%', px: '1%'}}>
                                <LocationOfInjuries 
                                key={key}
                                label={convertJSONKeyToTitle(key)} 
                                initialValue={initialValues[key]} 
                                disabled={props.disabled}
                                onChange={(event) => {
                                    setInitialValues((currentValues) => ({
                                        ...currentValues,
                                        [key]: event
                                    }));
                                    !props.dirty && props.setDirty(true);
                                }}/>
                            </Box>)
                        } else if (props.formFields[key]["display"] === "table" && !("headers" in props.formFields[key])) {
                            return (
                                <Box sx={{ flex: '100%', pl: '1rem' }}>
                                    <VitalsTableInput
                                        key={key}
                                        label={convertJSONKeyToTitle(key)}
                                        disabled={props.disabled}
                                        labelMinWidth={`${maxLabelWidth * 8}px`} // Approximate character width
                                        defaultValue={formSectionInputs.current[key]}
                                        value={initialValues[key]}
                                        onChange={(value) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: value,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}
                                    />
                                </Box>
                            );
                        } else if (props.formFields[key]["display"] === "table" && ("headers" in props.formFields[key])) {
                                return (
                                    <Box sx={{ flex: '100%', pl: '1rem' }}>
                                        <HeadersTableInput
                                            key={key}
                                            label={convertJSONKeyToTitle(key)}
                                            disabled={props.disabled}
                                            headers={props.formFields[key].headers}
                                            orientation={false}
                                            value={initialValues[key]}
                                            onChange={(value) => {
                                                setInitialValues((currentValues) => ({
                                                    ...currentValues,
                                                    [key]: value,
                                                }));
                                                !props.dirty && props.setDirty(true);
                                            }}
                                        />
                                    </Box>
                                );
                        }
                    }  else if (props.formFields[key]["type"] === 'dictionary') {
                        if (props.formFields[key]["display"] === "table" && "headers" in props.formFields[key] && props.formFields[key]["orientation"] === "horizontal") {
                            return (
                                <Box sx={{ flex: '100%', pl: '1rem' }}>
                                    <HeadersTableInput
                                        key={key}
                                        label={convertJSONKeyToTitle(key)}
                                        disabled={props.disabled}
                                        headers={props.formFields[key].headers}
                                        orientation={true}
                                        value={initialValues[key]}
                                        onChange={(value) => {
                                            setInitialValues((currentValues) => ({
                                                ...currentValues,
                                                [key]: value,
                                            }));
                                            !props.dirty && props.setDirty(true);
                                        }}
                                    />
                                </Box>
                            );
                        }
                    }
                    return <Box>
                    </Box>
                })}
        </Box>
    </form>
}

export default AutoBuildFormSection;

