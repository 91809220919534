
import { putAudioIntoPresigned } from "./AWSHelpers";
import { axiosInstance } from "./AxiosConfiguration";


export async function createVisit(patientDetails, visitDetails) {
    try {
        // Format datetime objects for incident_occurred_at and efrt_activated_at
        const response = await axiosInstance.post('/api/v1/visits', {
            "patient_info": patientDetails,
            "details": {
                "incident_occurred_at": visitDetails["date"],
                "efrt_activated_at": visitDetails["efrt_activation_time"] || "1901-01-01T00:00:00",
                "ambulance_number": visitDetails["ambulance_number"] || "",
                "transport": visitDetails["transport"],
            }
        })
        return response.data.id;
    } catch (error) {
        console.log(error);
        throw new Error(error);
    }
}

export async function updateVisit(visitID, visitDetails, patientInfo, currentDetails, visitTitle) {
    // Cut generated result out
    for (let section in visitDetails) {
        if (!("user_edit" in visitDetails[section])) {
            visitDetails[section] = {
                "user_edit": {
                    "value": {
                        ...visitDetails[section]
                    }
                }
            }
        }
    }


    visitDetails['patient_info'] = patientInfo;
    visitDetails['title'] = visitTitle;
    // Format details
    visitDetails['details'] = {
        "incident_occurred_at": currentDetails["date"],
        "efrt_activated_at": currentDetails["efrt_activation_time"] || "",
        "ambulance_number": currentDetails["ambulance_number"] || "",
        "transport": currentDetails["transport"],
    };

    await axiosInstance.put(`/api/v1/visits/${visitID}`, visitDetails).catch(error => {
        throw new Error(error);
    })
    console.log("Updated");
}

export async function uploadAudio(audioChunks, codec = null, visitID) {
    const audioBlob = codec ? new Blob(audioChunks, {type : codec}) : new Blob(audioChunks);
    try {
        // fetch presigned url
        let uploadParams;
        await axiosInstance.get('/api/v1/upload/url').then(response => {
            uploadParams = response.data;
        })

        // upload audio to presigned URL
        await putAudioIntoPresigned(uploadParams, audioBlob);

        // make postrequest to start workflow using visitID
        await axiosInstance.post(`/api/v1/visits/${visitID}/workflow`, {
            "recording_uri": uploadParams['fields']['key'],
        })

        return { success: true, message: "Audio uploaded successfully and workflow started!" };
    } catch (error) {
        console.error("Error in uploadAudio:", error);
        return { success: false, message: "Error trying to upload audio and start workflow." };
    }
}

export async function getVisitWithID(id) {
    try {
        const response = await axiosInstance.get(`/api/v1/visits/${id}`)

        return response.data
    } catch(error) {
        console.log(`Error retrieving report with ${id}`, error);
        return null;
    }
}